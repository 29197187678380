import React, { useState, Suspense } from "react";
import Login from "./components/pages/Login";

//const App = React.lazy(() => import("./App"));
const AppPromise = import("./App");
const App = React.lazy(() => AppPromise);

function LoggedApp() {
  const [user, setUser] = useState("");

  function callBackSetUser(loggedUser: string) {
    console.log("callBackSetUser " + loggedUser);
    setUser(loggedUser);
  }

  return (
    <>
      {user === "" ? (
        <Login setBackUser={callBackSetUser} />
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <App user={user} />
        </Suspense>
      )}
    </>
  );
}

export default LoggedApp;
