import React, { useState } from "react";

function Login(props: any) {
  const [email, setEmail] = useState("");

  function handleChange(event: any) {
    const { name, value } = event.target;
    setEmail(value);
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    console.log("handleSubmit " + email);

    props.setBackUser(email);
  }

  return (
    <section className="h-screen bg-white">
      <div className="container px-4 mx-auto">
        <div className="max-w-sm mx-auto">
          <div className="mb-6 text-center">
            <a className="inline-block mb-6" href="#">
              <img
                className="h-16"
                src="flex-ui-assets/logos/flex-circle-green.svg"
                alt=""
              />
            </a>
            <h3 className="mb-4 text-2xl md:text-3xl font-bold">
              Sign in to your account
            </h3>
            <p className="text-lg text-coolGray-500 font-medium">
              Start your demo version
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label
                className="block mb-2 text-coolGray-800 font-medium"
                htmlFor="email"
              >
                Email
              </label>
              <input
                name="email"
                className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                type="email"
                onChange={handleChange}
                placeholder="dev@shuffle.dev"
              />
            </div>

            <div className="mb-4">
              <label
                className="block mb-2 text-coolGray-800 font-medium"
                htmlFor=""
              >
                Password
              </label>
              <input
                className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                type="password"
                placeholder="************"
              />
            </div>

            <div className="flex flex-wrap items-center justify-between mb-6">
              <div className="w-full md:w-1/2">
                <label className="relative inline-flex items-center">
                  <input
                    className="form-checkbox appearance-none"
                    type="checkbox"
                  />
                  <img
                    className="absolute top-1/2 transform -translate-y-1/2 left-0"
                    src="flex-ui-assets/elements/sign-up/checkbox-icon.svg"
                    alt=""
                  />
                  <span className="ml-7 text-xs text-coolGray-800 font-medium">
                    Remember me
                  </span>
                </label>
              </div>
              <div className="w-full md:w-auto mt-1">
                <a
                  className="inline-block text-xs font-medium text-green-500 hover:text-green-600"
                  href="#"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <button className="inline-block py-3 px-7 mb-6 w-full text-base text-green-50 font-medium text-center leading-6 bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm">
              Sign In
            </button>
            <p className="text-center">
              <span className="text-xs font-medium">
                Don’t have an account?
              </span>
              <a
                className="inline-block text-xs font-medium text-green-500 hover:text-green-600 hover:underline"
                href="#"
              >
                Sign up
              </a>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;
